// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableHeader from "../../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as SuspiciousIp from "../../../../../models/SuspiciousIp.res.js";
import * as TableHeaderCell from "../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as Api_SuspiciousIp from "../../../../../api/suspicious-ips/Api_SuspiciousIp.res.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as DashboardTableCss from "../../../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_SuspiciousEmail from "../../../../../routes/common/Routes_SuspiciousEmail.res.js";

var initialState_suspiciousEmails = [];

var initialState = {
  status: "FetchingSuspiciousEmails",
  currentPage: 1,
  totalPages: 1,
  suspiciousEmails: initialState_suspiciousEmails
};

function DashboardSuspiciousIpEditEmails(props) {
  var suspiciousIp = props.suspiciousIp;
  var container = React.useRef(null);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchSuspiciousEmails") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          $$Promise.wait(Api_SuspiciousIp.Dashboard.SuspiciousEmail.index(suspiciousIp.id, param.state.currentPage), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedSuspiciousEmailsFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "DashboardSuspiciousIpEditEmails",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "DashboardSuspiciousIpEditEmails.make"
                                      }, "Dashboard::FetchSuspiciousEmails::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailSuspiciousEmailsFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: "FailedSuspiciousEmailsFetch",
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        suspiciousEmails: state.suspiciousEmails
                      }
                    };
            }
          }
          if (action.TAG === "SucceedSuspiciousEmailsFetch") {
            var res = action._0;
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: {
                        TAG: "Ready",
                        _0: res.suspiciousEmails
                      },
                      currentPage: res.currentPage,
                      totalPages: res.totalPages,
                      suspiciousEmails: state.suspiciousEmails
                    },
                    _1: (function (param) {
                        Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                return prim.scrollTop;
                              }));
                      })
                  };
          }
          var nextPage = action._0;
          var match = state.status;
          if (typeof match !== "object" && match === "FetchingSuspiciousEmails") {
            return "NoUpdate";
          }
          if (state.currentPage !== nextPage) {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: "FetchingSuspiciousEmails",
                      currentPage: nextPage,
                      totalPages: state.totalPages,
                      suspiciousEmails: state.suspiciousEmails
                    },
                    _1: (function (param) {
                        param.dispatch("FetchSuspiciousEmails");
                      })
                  };
          } else {
            return "NoUpdate";
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchSuspiciousEmails");
        }), []);
  var suspiciousEmails = state.status;
  var tmp;
  tmp = typeof suspiciousEmails !== "object" ? (
      suspiciousEmails === "FetchingSuspiciousEmails" ? JsxRuntime.jsx(TableRow.make, {
              children: JsxRuntime.jsx(TableCell.make, {
                    colSpan: 4,
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(Spinner.make, {
                                size: "XL",
                                color: "Teal"
                              }),
                          className: DashboardTabsCss.Tab.spinner
                        })
                  })
            }) : JsxRuntime.jsx(TableRow.make, {
              className: DashboardTableCss.fetchError,
              children: JsxRuntime.jsx(TableCell.make, {
                    colSpan: 4,
                    children: "Failed to fetch suspicious emails."
                  })
            })
    ) : Belt_Array.mapWithIndex(suspiciousEmails._0, (function (index, suspiciousEmail) {
            return JsxRuntime.jsxs(TableRow.make, {
                        children: [
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("a", {
                                      children: suspiciousEmail.email,
                                      className: DashboardTableCss.tableLink,
                                      href: Routes_SuspiciousEmail.Dashboard.edit(suspiciousEmail.id)
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx(SuspiciousIp.Badge.make, {
                                      status: SuspiciousIp.statusFromString(suspiciousEmail.status)
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: String(suspiciousEmail.invalidAttempts)
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsx(Popover.Trigger.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          children: JsxRuntime.jsx(IconMenu.make, {
                                                                size: "MD",
                                                                color: "Teal"
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "RightEdge"
                                                    },
                                                    className: DashboardTabsCss.Tab.Dropdown.body,
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          className: DashboardTabsCss.Tab.Dropdown.bodyRow,
                                                          children: JsxRuntime.jsx("a", {
                                                                children: "Edit",
                                                                className: DashboardTabsCss.Tab.Dropdown.bodyLink,
                                                                href: Routes_SuspiciousEmail.Dashboard.edit(suspiciousEmail.id)
                                                              })
                                                        })
                                                  })
                                            ]
                                          }),
                                      className: DashboardTabsCss.Tab.Dropdown.menu
                                    })
                              })
                        ]
                      }, String(index));
          }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(Table.make, {
                            className: DashboardTableCss.table,
                            children: [
                              JsxRuntime.jsx(TableHeader.make, {
                                    children: JsxRuntime.jsxs(TableRow.make, {
                                          children: [
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: "Email"
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: "Status"
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: "Invalid Attempts"
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: ""
                                                })
                                          ]
                                        })
                                  }),
                              JsxRuntime.jsx(TableBody.make, {
                                    children: tmp
                                  })
                            ]
                          }),
                      className: DashboardTableCss.tableWrapper
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (x) {
                                dispatch({
                                      TAG: "UpdatePage",
                                      _0: x
                                    });
                              })
                          }),
                      className: DashboardTabsCss.Tab.pagination
                    })
              ],
              className: DashboardTabsCss.Tab.Section.container
            });
}

var Css;

var TableCss;

var make = DashboardSuspiciousIpEditEmails;

export {
  Css ,
  TableCss ,
  initialState ,
  make ,
}
/* Hooks Not a pure module */
